@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .scrollbar::-webkit-scrollbar {
        width: 20px;
        height: 20px;
    }
    .scrollbar::-webkit-scrollbar-track {
        /* border-radius: 100vh; */
        background: theme('colors.base-content');
    }
    .scrollbar::-webkit-scrollbar-thumb {
        background: theme('colors.neutral');
        border-radius: 100vh;
        border: 2px solid theme('colors.accent-content');
    }
    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: theme('colors.neutral-focus');
    }
}